import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://9ea3e0e6914afe7110d20754aa6e318d@sentry.flexi-servers.com/5",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});